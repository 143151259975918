<template>
  <DynamicView>
    <TableView
      :heading="$t(`pages.assetManagement`)"
      :description="$t(`assetManagement.fullRights`)"
      v-bind="{ buttons, tabs, hasPagination }"
    />
    <CreateObjectDialog v-model="createObjectDialog" @create="objectCreated" />
    <CreateObjectGroupDialog
      v-model="createGroupDialog"
      @finish="groupCreated"
    />
  </DynamicView>
</template>

<script>
import { useStore } from 'vuex'
import { EUserRole } from '@/enums'
import { user } from '@/helpers'
import { TableView } from '@/components/layout'
import DynamicView from '@/components/layout/DynamicView'
import {
  CreateObjectDialog,
  EditObjectGroupDialog as CreateObjectGroupDialog,
} from '@/components/dialog'
import { useRequest } from '@/composition'
import {
  objectGroups as objectGroupsApi,
  objects as objectsApi,
} from '@/services/api'

export default {
  components: {
    TableView,
    DynamicView,
    CreateObjectDialog,
    CreateObjectGroupDialog,
  },
  data() {
    return {
      createObjectDialog: false,
      createGroupDialog: false,
      newObject: {
        value: null, // nested to enable reactive injection
      },
      newGroup: {
        value: null,
      },
      assetGroupsCount: 0,
      assetsCount: 0,
      hasPagination: (this.hasPagination =
        this.$route.path !== '/asset-management/access-request'),
    }
  },
  setup() {
    const store = useStore()

    const organisationIsTestAccount =
      store.getters['auth/organisationIsTestAccount']

    const assetGroupsRequest = useRequest(() => {
      return objectGroupsApi.getList()
    })

    const assetsRequest = useRequest(() => {
      return objectsApi.getList()
    })

    return {
      organisationIsTestAccount,
      assetGroupsRequest,
      assetsRequest,
    }
  },
  provide() {
    return {
      newObject: this.newObject,
      newGroup: this.newGroup,
      deleteObject: () => this.assetsCount--,
      createObject: () => this.assetsCount++,
      deleteObjectGroup: () => this.assetGroupsCount--,
    }
  },
  computed: {
    buttons() {
      if (!this.canCreate()) {
        return []
      }

      return [
        {
          label: this.$t('assetManagement.createObjectGroup'),
          onClick: this.openCreateGroupDialog,
          disabled:
            this.organisationIsTestAccount && this.assetGroupsCount >= 1,
        },
        {
          label: this.$t('assetManagement.createObject'),
          onClick: this.openCreateObjectDialog,
          disabled: this.organisationIsTestAccount && this.assetsCount >= 1,
        },
      ]
    },
    tabs() {
      let tabs = [
        {
          routeName: 'objectGroupManagement',
          label: this.$t('assetManagement.objectGroups-tab'),
        },
        {
          routeName: 'objectManagement',
          label: this.$t('assetManagement.objects-tab'),
        },
      ]

      if (user.hasPermission([EUserRole.ProspectiveBuyer])) {
        tabs.push({
          routeName: 'accessRequest',
          label: this.$t('assetManagement.access-request-tab'),
        })
      }

      return tabs
    },
  },
  methods: {
    canCreate() {
      const store = useStore()
      return (
        user.hasPermission([
          EUserRole.ObjectAdministrator,
          EUserRole.AccountAdministrator,
          EUserRole.ProspectiveBuyer,
        ]) && !store.getters['auth/organisationIsFinancePartner']
      )
    },
    openCreateObjectDialog() {
      this.createObjectDialog = true
    },
    openCreateGroupDialog() {
      this.createGroupDialog = true
    },
    objectCreated(object) {
      this.newObject.value = object
      this.assetsCount++
    },
    groupCreated(group) {
      this.newGroup.value = group
      this.assetGroupsCount++
    },
    async fetchAssetGroupsCount() {
      if (!this.organisationIsTestAccount) {
        return
      }

      const assetGroupsList = await this.assetGroupsRequest.request()
      this.assetGroupsCount = assetGroupsList.length
    },
    async fetchAssetsCount() {
      if (!this.organisationIsTestAccount) {
        return
      }

      const assetsList = await this.assetsRequest.request()
      this.assetsCount = assetsList.length
    },
  },
  watch: {
    organisationIsTestAccount: {
      handler: function () {
        this.fetchAssetGroupsCount()
        this.fetchAssetsCount()
      },
      immediate: true,
    },
    $route(value) {
      this.hasPagination = value?.path !== '/asset-management/access-request'
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === 'home' && !to.redirectedFrom) {
        if (to.name === 'objectManagement') {
          vm.openCreateObjectDialog()
        } else if (to.name === 'objectGroupManagement') {
          vm.openCreateGroupDialog()
        }
      }
    })
  },
}
</script>
